import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import googleImg from "../images/googlePlay.png"
import axios from "axios"
import { baseUrl } from "../config/keys"
import {
  Nav,
  NavItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import Logo from "./Logo"

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      dropdownOpen: false,
    }
  }

  toggle = () => this.setState({ dropdownOpen: !this.state.dropdownOpen })
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  submitHandler = (e) => {
    e.preventDefault()

    axios
      .post(`${baseUrl}/korerroMail?email=${this.state.email}`)
      .then((res) => {
        alert("Thanks for interest in Korero")
      })
      .catch((err) => console.log(err))
  }
  render() {
    const { email } = this.props

    return (
      <React.Fragment>
        <ShowDesktop>
          <FooterWrapper>
            <FooterLeftBox>
              <LogoWrapper>
                <Logo style={{ width: "500px", height: "500px" }} />
              </LogoWrapper>
              <ContactUsHeader>Contact Us</ContactUsHeader>
              <Address>
                <AddressBox1>
                  {" "}
                  <i class="fa fa-phone" style={{ color: "black" }} />
                  &nbsp; +31-683532012
                </AddressBox1>
                <AddressBox2>
                  {" "}
                  <i class="fa fa-envelope" style={{ color: "black" }} />
                  &nbsp;support@tekorero.com
                </AddressBox2>
                <AddressBox3>
                  <i
                    class="fa fa-map-marker"
                    style={{
                      color: "black",
                    }}
                  />
                  &nbsp; Rotterdam, The Netherlands, 2662CT <br />{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;Bhubaneswar, Odisha, India, 751013
                </AddressBox3>
              </Address>
              <SocialIcon>
                <a
                  href="https://play.google.com/store/apps/details?id=com.Tekorero"
                  target="_blank"
                >
                  <GooglePlayButton>
                    <img src={googleImg} height="45px" />
                  </GooglePlayButton>
                </a>

                <a
                  href="https://youtube.com/channel/UCTE9DDVhMesJkX2VXHC1v4Q"
                  target="_blank"
                >
                  <i
                    className="fa fa-youtube-play"
                    style={{
                      color: " red ",
                      fontSize: "40px",
                      marginRight: "10px",
                    }}
                    aria-hidden="true"
                  ></i>
                </a>
                <a
                  href="https://www.facebook.com/Korero-CRM-Sales-Automation-102686918459525/?view_public_for=102686918459525"
                  target="_blank"
                >
                  <i
                    className="fa fa-facebook-square fa-2x"
                    style={{
                      color: " #3b5998 ",
                      fontSize: "40px",
                      marginRight: "10px",
                    }}
                    aria-hidden="true"
                  ></i>
                </a>
                {/* <a
                  href="https://www.instagram.com/korero_crm_salesautomation/"
                  target="_blank"
                >
                  <i
                    id="insta"
                    className="fa fa-instagram"
                    style={{ fontSize: "40px", marginRight: "10px" }}
                    aria-hidden="true"
                  ></i>
                </a> */}
                <a href="https://www.linkedin.com/company/9412487/admin/">
                  <i
                    className="fa fa-linkedin-square fa-2x"
                    style={{
                      color: " #0077B5 ",
                      fontSize: "40px",
                      marginRight: "10px",
                    }}
                    aria-hidden="true"
                  ></i>
                </a>
                <script
                  src="https://platform.linkedin.com/in.js"
                  type="text/javascript"
                >
                  {" "}
                  lang: en_US
                </script>
                <script
                  type="IN/FollowCompany"
                  data-id="9412487"
                  data-counter="bottom"
                ></script>
              </SocialIcon>
            </FooterLeftBox>
            <FooterRightBox>
              <FooterMenu>
                <Nav>
                  <StyledNavItem>
                    {/* <div activeStyle={{ color: "black" }}> */}

                    <Dropdown
                      style={{
                        marginBottom: "0px",
                        marginRight: "25px",
                        color: "black",
                        padding: "0px",
                      }}
                      // nav
                      isOpen={this.state.dropdownOpen}
                      toggle={this.toggle}
                    >
                      <DropdownToggle nav>Features</DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem>
                          <MenuLink
                            to="/outoftheboxuse/"
                            activeStyle={{ color: "#00bfff" }}
                          >
                            Out-of-the-Box
                          </MenuLink>
                        </DropdownItem>
                        <DropdownItem>
                          {" "}
                          <MenuLink
                            to="/customerjourney/"
                            activeStyle={{ color: "#00bfff" }}
                          >
                            Customer journey
                          </MenuLink>
                        </DropdownItem>
                        <DropdownItem>
                          {" "}
                          <MenuLink
                            to="/automation/"
                            activeStyle={{ color: "#00bfff" }}
                          >
                            Automation - value to business
                          </MenuLink>
                        </DropdownItem>

                        <DropdownItem>
                          {" "}
                          <MenuLink
                            SubLinkMobile
                            to="/leadsopportunitiesandclosures/"
                            activeStyle={{ color: "#00bfff" }}
                          >
                            Leads to Closures
                          </MenuLink>
                        </DropdownItem>
                        <DropdownItem>
                          {" "}
                          <MenuLink
                            to="/collaboratebreakthesilos/"
                            activeStyle={{ color: "#00bfff" }}
                          >
                            Collaborate "Break the silos"
                          </MenuLink>
                        </DropdownItem>
                        <DropdownItem>
                          {" "}
                          {/* <MenuLink
                            to="/securityandaccess/"
                            activeStyle={{ color: "#00bfff" }}
                          >
                            Security and Access
                          </MenuLink> */}
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    {/* </div> */}
                  </StyledNavItem>
                  <StyledNavItem>
                    <MenuLink to="/pricing/" activeStyle={{ color: "#00bfff" }}>
                      Pricing
                    </MenuLink>
                  </StyledNavItem>
                  {/* <div class="test"> */}
                  {/* <StyledNavItem>
                    <MenuLink
                      to="/valueadds/"
                      activeStyle={{ color: "#00bfff" }}
                    >
                      Value Adds
                    </MenuLink>
                  </StyledNavItem>

                  <StyledNavItem>
                    <MenuLink
                      to="/solutions/"
                      activeStyle={{ color: "#00bfff" }}
                    >
                      Solutions
                    </MenuLink>
                  </StyledNavItem> */}

                  <StyledNavItem>
                    <MenuLink
                      to="/knowledgehub/"
                      activeStyle={{ color: "#00bfff" }}
                    >
                      Knowledge Hub
                    </MenuLink>
                  </StyledNavItem>
                </Nav>
              </FooterMenu>
              <EmailBoxHeader>Let's Keep in Touch</EmailBoxHeader>
              <FormWrapper>
                <InputBox
                  type="text"
                  name="email"
                  onChange={this.changeHandler}
                  value={email}
                  placeholder="Email address"
                ></InputBox>

                <SubmitButton onClick={this.submitHandler} disabled>
                  Submit
                </SubmitButton>
              </FormWrapper>
              <StaticText>
                By Clicking submit, you acknowledge that you have read and agree
                to Korero's privacy policy.
              </StaticText>
              <CopyRightText>
                © {new Date().getFullYear()} {` `} Korero{" "}
                <Link to="/privacypolicy/">Privacy Policy </Link>{" "}
                <Link to="/termscondition/">Terms of Service</Link>
              </CopyRightText>
            </FooterRightBox>
          </FooterWrapper>
        </ShowDesktop>
        <ShowMobile>
          {" "}
          <FooterWrapperForMobile>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            <FooterMenu>
              <Nav>
                <StyledNavItem>
                  {/* <div activeStyle={{ color: "black" }}> */}

                  <Dropdown
                    style={{
                      color: "#00bfff",
                      paddingLeft: "0px",
                    }}
                    // nav
                    isOpen={this.state.dropdownOpen}
                    toggle={this.toggle}
                  >
                    <DropdownToggle nav>Features</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <MenuLink
                          to="/outoftheboxuse/"
                          activeStyle={{ color: "#00bfff" }}
                        >
                          Out-of-the-Box
                        </MenuLink>
                      </DropdownItem>
                      <DropdownItem>
                        {" "}
                        <MenuLink
                          to="/customerjourney/"
                          activeStyle={{ color: "#00bfff" }}
                        >
                          Customer journey
                        </MenuLink>
                      </DropdownItem>
                      <DropdownItem>
                        {" "}
                        <MenuLink
                          to="/automation/"
                          activeStyle={{ color: "#00bfff" }}
                        >
                          Automation - value to business
                        </MenuLink>
                      </DropdownItem>

                      <DropdownItem>
                        {" "}
                        <MenuLink
                          SubLinkMobile
                          to="/leadsopportunitiesandclosures/"
                          activeStyle={{ color: "#00bfff" }}
                        >
                          Leads to Closures
                        </MenuLink>
                      </DropdownItem>
                      <DropdownItem>
                        {" "}
                        <MenuLink
                          to="/collaboratebreakthesilos/"
                          activeStyle={{ color: "#00bfff" }}
                        >
                          Collaborate "Break the silos"
                        </MenuLink>
                      </DropdownItem>
                      <DropdownItem>
                        {" "}
                        {/* <MenuLink
                          to="/securityandaccess/"
                          activeStyle={{ color: "#00bfff" }}
                        >
                          Security and Access
                        </MenuLink> */}
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  {/* </div> */}
                </StyledNavItem>
                <StyledNavItem>
                  <MenuLink to="/pricing/" activeStyle={{ color: "#00bfff" }}>
                    Pricing
                  </MenuLink>
                </StyledNavItem>
                {/* <div class="test"> */}
                {/* <StyledNavItem>
                  <MenuLink to="/valueadds/" activeStyle={{ color: "#00bfff" }}>
                    Value Adds
                  </MenuLink>
                </StyledNavItem>

                <StyledNavItem>
                  <MenuLink to="/solutions/" activeStyle={{ color: "#00bfff" }}>
                    Solutions
                  </MenuLink>
                </StyledNavItem> */}

                <StyledNavItem>
                  <MenuLink
                    to="/knowledgehub/"
                    activeStyle={{ color: "#00bfff" }}
                  >
                    Knowledge Hub
                  </MenuLink>
                </StyledNavItem>
              </Nav>
            </FooterMenu>
            <EmailBoxHeader>Let's Keep in Touch</EmailBoxHeader>
            <FormWrapper>
              <InputBox
                type="text"
                name="email"
                onChange={this.changeHandler}
                value={email}
                placeholder="Email address"
              ></InputBox>

              <SubmitButton onClick={this.submitHandler} disabled>
                Submit
              </SubmitButton>
            </FormWrapper>
            <StaticText>
              By Clicking submit, you acknowledge that you have read and agree
              to korero's privacy policy.
            </StaticText>
            <ContactUsHeader>Contact Us</ContactUsHeader>
            <Address>
              <AddressBox1>
                {" "}
                <i class="fa fa-phone" style={{ color: "black" }} />
                &nbsp;+31-683532012
              </AddressBox1>
              <AddressBox2>
                {" "}
                <i class="fa fa-envelope" style={{ color: "black" }} />
                &nbsp;support@tekorero.com
              </AddressBox2>
              <AddressBox3>
                <i
                  class="fa fa-map-marker"
                  style={{
                    color: "black",
                  }}
                />
                &nbsp; Rotterdam, The Netherlands, 2662CT <br />{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;Bhubaneswar, Odisha, India, 751013
              </AddressBox3>
            </Address>
            <SocialIcon>
              <a
                href="https://play.google.com/store/apps/details?id=com.Tekorero"
                target="_blank"
              >
                <GooglePlayButton>
                  <img src={googleImg} height="45px" />
                </GooglePlayButton>
              </a>

              <a
                href="https://youtube.com/channel/UCTE9DDVhMesJkX2VXHC1v4Q"
                target="_blank"
              >
                <i
                  className="fa fa-youtube-play"
                  style={{
                    color: " red ",
                    fontSize: "40px",
                    marginRight: "10px",
                  }}
                  aria-hidden="true"
                ></i>
              </a>
              <a
                href="https://www.facebook.com/Korero-CRM-Sales-Automation-102686918459525/?view_public_for=102686918459525"
                target="_blank"
              >
                <i
                  className="fa fa-facebook-square fa-2x"
                  style={{
                    color: " #3b5998 ",
                    fontSize: "40px",
                    marginRight: "10px",
                  }}
                  aria-hidden="true"
                ></i>
              </a>
              {/* <a
                href="https://www.instagram.com/korero_crm_salesautomation/"
                target="_blank"
              >
                <i
                  id="insta"
                  className="fa fa-instagram"
                  style={{ fontSize: "40px", marginRight: "10px" }}
                  aria-hidden="true"
                ></i>
              </a> */}
              <a href="https://www.linkedin.com/company/9412487/admin/">
                <i
                  className="fa fa-linkedin-square fa-2x"
                  style={{
                    color: " #0077B5 ",
                    fontSize: "40px",
                    marginRight: "10px",
                  }}
                  aria-hidden="true"
                ></i>
              </a>
              <MobileNotShow>
                <script
                  src="https://platform.linkedin.com/in.js"
                  type="text/javascript"
                >
                  {" "}
                  lang: en_US
                </script>
                <script
                  type="IN/FollowCompany"
                  data-id="9412487"
                  data-counter="bottom"
                ></script>
              </MobileNotShow>
            </SocialIcon>{" "}
            <CopyRightText>
              © {new Date().getFullYear()} {` `} Korero .{" "}
              <Link to="/privacypolicy/">Privacy Policy </Link>{" "}
              <Link to="/termscondition/">Terms of Service</Link>
            </CopyRightText>
          </FooterWrapperForMobile>
        </ShowMobile>
      </React.Fragment>
    )
  }
}
export default Footer

const ShowDesktop = styled.div`
  @media screen and (max-width: 600px) {
    display: none;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    display: none;
  }
`
const ShowMobile = styled.div`
  padding-top: 30px;
  background-color: #ececec;
  @media only screen and (min-width: 1200px) {
    display: none;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    display: none;
  }
`
const FooterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2em 2em;
  padding: 10px;
  background-color: #ececec;
`
const FooterWrapperForMobile = styled.div`
  padding: 12px;
  background-color: #ececec;
`
const FooterLeftBox = styled.div`
  padding: 20px;
`
const LogoWrapper = styled.div`
  width: 180px;
`
const ContactUsHeader = styled.div`
  font-family: "Roboto";
  font-size: 1.125em;
  font-weight: 500;
  margin-top: 20px;

  @media screen and (max-width: 600px) {
    margin-top: 40px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    margin-top: 40px;
  }
`
const Address = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  @media screen and (max-width: 600px) {
    grid-template-columns: 45% 55%;
  }
`
const AddressBox1 = styled.div`
  font-size: 1.125em;
  font-family: "Roboto";
  font-weight: 200;
  @media screen and (max-width: 600px) {
    font-size: 0.938em;
  }
`
const AddressBox2 = styled.div`
  font-size: 1.125em;
  font-weight: 100;
  font-family: "Roboto";
  @media screen and (max-width: 600px) {
    font-size: 0.938em;
  }
`
const AddressBox3 = styled.div`
  grid-column: 1/3;
  font-size: 1.125em;
  @media screen and (max-width: 600px) {
    font-size: 0.938em;
  }
`

const FooterRightBox = styled.div`
  padding: 20px;
`

const SocialIcon = styled.div`
  display: flex;

  margin-top: 30px;
  align-items: center;
  @media screen and (max-width: 600px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const EmailBoxHeader = styled.div`
  margin-top: 20px;
  @media screen and (max-width: 600px) {
    margin-top: 40px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    margin-top: 40px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
  }
`

const FormWrapper = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  @media screen and (max-width: 600px) {
  }
`
const SubmitButton = styled.button`
  display: inline-block;
  text-decoration: none;
  line-height: 28px !important;
  min-height: 0 !important;
  background: lightcoral;
  padding: 0 28px;
  font-size: 1em;
  color: white;
  font-family: "Roboto";
  width: 200px;
  font-weight: 500;
  @media screen and (max-width: 600px) {
    width: 100px;
    padding: 0px 20px;
  }
`
const InputBox = styled.input`
  padding: 0px 15px;
  height: 40px;
  border: none;
  background-color: white;
`
const StaticText = styled.p`
  font-size: 1.125em;
  color: black;
  font-family: "Roboto";
  text-align: left;
  margin: 10px 0px;
  font-weight: 100;
  @media screen and (max-width: 600px) {
    font-size: 0.75em;
  }
`
const CopyRightText = styled.p`
  font-size: 1.125em;
  color: black;
  font-family: "Roboto";
  text-align: right;
  margin-top: 40px;
  font-weight: 500;
  @media screen and (max-width: 600px) {
    text-align: center;
    font-size: 0.938em;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    text-align: center;
  }
`
const GooglePlayButton = styled.div`
  margin-right: 10px;
`
const FooterMenu = styled.div`
  height: 46.6px;
  @media screen and (max-width: 600px) {
    margin-top: 40px;
    height: auto;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    margin-top: 40px;
    height: auto;
  }
`

const StyledNavItem = styled(NavItem)`
  margin-bottom: 0px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 992px) {
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
  }
`

const MenuLink = styled(Link)`
  font-family: " Roboto";
  font-style: normal;
  font-weight: 100;
  font-size: 1.125em;
  line-height: 26px;
  color: #333333;
  text-decoration: none;
  text-transform: inherit;
  margin-right: 25px;

  &:hover {
    text-decoration: none;
  }
  @media screen and (min-width: 992px) {
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    margin-right: 22px;
    color: black;
    font-size: 1em;
    &:hover {
      text-decoration: none;
    }
  }
`
const MobileNotShow = styled.div`
  @media screen and (max-width: 600px) {
    display: none;
  }
`
