import React from "react"
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap"
import { Link } from "gatsby"
import Logo from "./Logo"
import styled from "styled-components"
import { loginUrl } from "../config/keys"
import "./Nav.css"

class SmallNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }
  changeHandler = (e) => {
    console.log(e.target.value)
    console.log(e.target.name)
    this.setState({ [e.target.name]: e.target.value })
    console.log(this.state.intrestIn)
  }
  render() {
    return (
      <div>
        <HeaderWrapper1 expand="md" fixed="top">
          <Nav className="ml-auto" navbar>
            <StyledNavItem>
              <MenuLink
                to="/contacts/"
                style={{ color: "#00bfff" }}
                activeStyle={{ color: "#00bfff" }}
              >
                <a> Reach Us</a>
              </MenuLink>
              <MenuLink>
                <i
                  class="fa fa-whatsapp"
                  style={{ color: "#25D366", fontSize: "18px" }}
                />{" "}
                <i
                  class="fa fa-phone"
                  style={{ color: "rgb(0, 191, 255)", marginRight: "5px" }}
                />
                +91-7846892841
                <i
                  id="gmail"
                  className="fa fa-envelope"
                  // className="fa fa-youtube-square fa-2x"
                  style={{
                    fontSize: "18px",
                    marginLeft: "15px",
                    marginRight: "5px",
                  }}
                  aria-hidden="true"
                ></i>
                support@tekorero.com
                <span style={{ fontFamily: "auto", marginLeft: "10px" }}></span>
              </MenuLink>
            </StyledNavItem>
          </Nav>
        </HeaderWrapper1>
      </div>
    )
  }
}

export default SmallNavbar

const HeaderWrapper1 = styled(Navbar)`
  background: white;
  height: 40px;
  padding: 0px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 992px) {
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    background: white;
  }
`

const StyledNavItem = styled(NavItem)`
  margin-bottom: 0px;
  /* padding-top: 6px; */
  @media screen and (max-width: 992px) {
    font-size: 1.2rem;
    text-align: center;
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    padding: 1rem;
    font-size: 1.2rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgb(102, 102, 102);
    text-align: center;
  }
`
const MenuLink = styled(Link)`
  color: #000;
  font-size: 0.938em;
  padding: 15px 0 16px;
  text-transform: inherit;
  line-height: 20px;
  margin-right: 10px;
  font-family: "Roboto";

  // &:hover {
  //   color: #000;
  //   text-shadow: 3px 3px 6px #215ca0;
  //   text-decoration: none;
  // }
  @media screen and (min-width: 992px) {
    color: black;
    padding: 0.1rem 0.4rem;
    text-decoration: none;
    margin-right: 10px;
    margin-top: 30px;
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    color: black;
    &:hover {
      color: black;
      text-shadow: 3px 3px 6px #215ca0;
      text-decoration: none;
    }
  }
`

const TrialLink = styled.a`
  /* display: inline-block; */
  text-decoration: none;
  // text-transform: uppercase;

  color: black;
  font-size: 18px;
  font-weight: bold;
  // font-family: Roboto;
  font-family: "Roboto";
  padding: 8px 19px 8px 2px;
  border-radius: 0.3rem;
  width: 100px;
  // margin-left: 30px;

  &:hover {
    color: #00bfff;
    // text-shadow: 3px 3px 6px #215ca0;
    text-decoration: none;
  }
  @media screen and (max-width: 992px) {
    margin-left: 0px;
    align-content: center;
    text-align: center;
    margin-right: 0px;
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    margin-left: 0px;
    align-content: center;
    text-align: center;
    margin-right: 0px;
    color: black;
  }
`
const TrialLink1 = styled.a`
  /* display: inline-block; */
  text-decoration: none;
  background: black;
  color: whitesmoke;
  font-size: 0.875em;
  font-weight: bold;
  font-family: "Roboto";
  padding: 8px 20px;
  border-radius: 0.1rem;

  margin-right: 20px;
  border-radius: 4px;

  &:hover {
    color: orange;
    text-decoration: none;
    text-shadow: "3px 3px 6px #215ca0";
  }
  @media screen and (max-width: 992px) {
    text-decoration: none;
    background: rgb(21 19 19 / 63%);
    color: whitesmoke;
    font-size: 0.875em;
    font-weight: bold;
    font-family: "Roboto";
    /* padding: 10px 18px; */
    border-radius: 0.1rem;
    padding-top: 9px;
    margin-left: -5px;
    margin-top: -32px;
    width: 293px;
    padding: 16px 17px;
    height: 52px;
    margin-right: -3px;
    border-radius: 8px;
  }

  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    // margin-left: 0px;
    // align-content: center;
    // text-align: center;
    // margin-right: 0px;
  }
`
const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  width: 150px;
  margin-left: 1rem;
  @media screen and (max-width: 600px) {
    height: 60px;
  }
`
