import React from "react"
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap"
import { Link } from "gatsby"
import Logo from "./Logo"
import styled from "styled-components"
import { loginUrl } from "../config/keys"
import Cookie from "../modules/Home/Cookie"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import login from "../images/login.jpg"
import SmallNavbar from "./SmallNavbar"
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap"

// import Navbar from "./Navbar"
import clsx from "clsx"
import { faBorderStyle } from "@fortawesome/free-solid-svg-icons"
// import { Button } from "bootstrap"

class MyNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
      dropdownOpen: false,
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }
  toggle1 = () => this.setState({ dropdownOpen: !this.state.dropdownOpen })
  changeHandler = (e) => {
    console.log(e.target.value)
    console.log(e.target.name)
    this.setState({ [e.target.name]: e.target.value })
    console.log(this.state.intrestIn)
  }
  render() {
    return (
      <React.Fragment>
        <HeaderWrapper expand="md" fixed="top">
          <Link to="/" style={{ textDecoration: "none" }}>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
          </Link>

          <NavbarToggler onClick={this.toggle}>
            {this.state.isOpen ? (
              <i
                className="fa fa-close"
                aria-hidden="true"
                style={{ color: "black", fontSize: "1.5rem" }}
              />
            ) : (
              <i
                className="fa fa-bars"
                aria-hidden="true"
                style={{ color: "black", fontSize: "1.5rem" }}
              />
            )}
          </NavbarToggler>

          <CollapseMobile isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <StyledNavItem>
                {this.props.show ? (
                  <MenuClick
                    style={{ color: this.props.show ? "#00bfff" : "#333333" }}
                    onClick={() => this.props.handleDropdownClick(false)}
                  >
                    Features
                  </MenuClick>
                ) : (
                  <MenuClick
                    style={{ color: this.props.show ? "#00bfff" : "#333333" }}
                    onClick={() => this.props.handleDropdownClick(true)}
                  >
                    Features
                  </MenuClick>
                )}
              </StyledNavItem>

              <MenuMobile>
                {this.props.show && (
                  <>
                    <SubLinkMobile
                      to="/outoftheboxuse/"
                      activeStyle={{ color: "#00bfff" }}
                    >
                      Out-of-the-Box
                    </SubLinkMobile>
                    <SubLinkMobile
                      to="/customerjourney/"
                      activeStyle={{ color: "#00bfff" }}
                    >
                      Customer journey
                    </SubLinkMobile>
                    <SubLinkMobile
                      to="/automation/"
                      activeStyle={{ color: "#00bfff" }}
                    >
                      Automation - value to business
                    </SubLinkMobile>
                    <SubLinkMobile
                      to="/leadsopportunitiesandclosures/"
                      activeStyle={{ color: "#00bfff" }}
                    >
                      Leads to Closures
                    </SubLinkMobile>
                    <SubLinkMobile
                      to="/collaboratebreakthesilos/"
                      activeStyle={{ color: "#00bfff" }}
                    >
                      Collaborate "Break the silos"
                    </SubLinkMobile>
                    {/* <SubLinkMobile
                      to="/securityandaccess/"
                      activeStyle={{ color: "#00bfff" }}
                    >
                      Security and Access
                    </SubLinkMobile> */}
                  </>
                )}
              </MenuMobile>

              <StyledNavItem>
                <MenuLink to="/pricing/" activeStyle={{ color: "#00bfff" }}>
                  Pricing
                </MenuLink>
              </StyledNavItem>
              {/* <div class="test"> */}
              {/* <StyledNavItem>
                <MenuLink to="/valueadds/" activeStyle={{ color: "#00bfff" }}>
                  Value Adds
                </MenuLink>
              </StyledNavItem> */}

              {/* <StyledNavItem>
                <MenuLink to="/solutions/" activeStyle={{ color: "#00bfff" }}>
                  Solutions
                </MenuLink>
              </StyledNavItem> */}

              {/* <StyledNavItem>
              <MenuLink
                to="/valueaddsNEW/"              
                activeStyle={{ color: "#00bfff" }}
              >                
                  Value Adds NEW               
              </MenuLink>
            </StyledNavItem> */}

              <StyledNavItem>
                <MenuLink
                  to="/knowledgehub/"
                  activeStyle={{ color: "#00bfff" }}
                >
                  Knowledge Hub
                </MenuLink>
              </StyledNavItem>
              <StyledNavItem>
                <SolutionsDropdown
                  style={{
                    marginBottom: "0px",
                    marginRight: "25px",
                    color: "black",
                    padding: "0px",
                  }}
                  // nav
                  isOpen={this.state.dropdownOpen}
                  toggle={this.toggle1}
                >
                  <DropdownToggle nav>Solutions</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>
                      <MenuLink to="/d2c/" activeStyle={{ color: "#00bfff" }}>
                        D2C
                      </MenuLink>
                    </DropdownItem>
                    <DropdownItem>
                      <MenuLink
                        to="/staffing/"
                        activeStyle={{ color: "#00bfff" }}
                      >
                        Staffing
                      </MenuLink>
                    </DropdownItem>
                  </DropdownMenu>
                </SolutionsDropdown>
              </StyledNavItem>
              <StyledNavItem>
                <MenuLink to="/contacts/" activeStyle={{ color: "#00bfff" }}>
                  Contact Us
                </MenuLink>
              </StyledNavItem>

              <BlockOnMobile>
                <StyledNavItem>
                  <TryItFreeButton
                    onClick={(e) => {
                      // To stop the page reloading
                      // e.preventDefault()
                      // Lets track that custom click
                      trackCustomEvent({
                        // string - required - The object that was interacted with (e.g.video)
                        category: "start your free trial  button in navbar ",
                        // string - required - Type of interaction (e.g. 'play')
                        action: "Click",
                        // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                        label: "Start your free trial button in navbar",
                        // number - optional - Numeric value associated with the event. (e.g. A product ID)
                        value: 43,
                      })
                      //... Other logic here
                    }}
                    href={`${loginUrl}/register/Option`}
                    target="_blank"
                  >
                    Try it Free
                  </TryItFreeButton>
                </StyledNavItem>
              </BlockOnMobile>

              <BlockOnMobile>
                <StyledNavItem>
                  <LoginButton href={`${loginUrl}`} target="_blank">
                    Login
                  </LoginButton>
                </StyledNavItem>
              </BlockOnMobile>

              <BlockOnLargeScreen>
                <StyledNavItem>
                  <LoginButton
                    href="https://play.google.com/store/apps/details?id=com.Tekorero"
                    target="_blank"
                  >
                    Login
                  </LoginButton>
                </StyledNavItem>
              </BlockOnLargeScreen>
            </Nav>
          </CollapseMobile>
          <Cookie />
        </HeaderWrapper>
      </React.Fragment>
    )
  }
}

export default MyNavbar

const HeaderWrapper = styled(Navbar)`
  background: white;
  height: 5.125em;
  padding: 0px !important;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-top: 40px; */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 992px) {
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    background: white;
    margin-top: 0px;
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) {
    background: white;
    margin-top: 0px;
  }
`

const CollapseMobile = styled(Collapse)`
  background: white;
  border-bottom: none;

  @media screen and (max-width: 992px) {
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    background: white;
    border-bottom: 1px solid rgb(102, 102, 102);
  }
`
// const NavbarWrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: nowrap;
//   justify-content: space-between;
//   align-items: center;
// `
// const MenuWrapper = styled.div`
//   margin-right: 0.5rem;
// `

const StyledNavItem = styled(NavItem)`
  margin-bottom: 0px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 992px) {
    font-size: 1.2rem;
    text-align: center;
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    padding: 1rem;
    font-size: 1.2rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgb(102, 102, 102);
    text-align: center;
  }
`
const MenuClick = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  font-size: 0.9em;
  line-height: 26px;
  color: #333333;
  text-decoration: none;
  text-transform: inherit;
  margin-right: 35px;
  cursor: pointer;
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    margin-right: 11px;
    font-size: 1em;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    margin-right: 11px;
    font-size: 1em;
  }

  &:hover {
    /* color: #000;
    text-shadow: 3px 3px 6px #215ca0; */
    text-decoration: none;
  }
`

const MenuLink = styled(Link)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  font-size: 0.9em;
  line-height: 26px;
  color: #333333;
  text-decoration: none;
  text-transform: inherit;
  margin-right: 35px;

  &:hover {
    /* color: #000;
    text-shadow: 3px 3px 6px #215ca0; */
    text-decoration: none;
  }
  @media screen and (min-width: 992px) {
    /* color: black;
    padding: 0.1rem 0.4rem;
    text-decoration: none;
    margin-right: 10px;
    margin-top: 30px; */
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    color: black;
    &:hover {
      color: black;
      text-shadow: 3px 3px 6px #215ca0;
      text-decoration: none;
    }
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    margin-right: 13px;
    font-size: 1em;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    margin-right: 13px;
    font-size: 1em;
  }
`

const LoginButton = styled.a`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin-right: 20px;
  font-family: "Roboto";
  border-radius: 4px;
  width: 100px;
  border: 2px solid #333333;
  border-radius: 4px;
  color: #333333;

  /* &:hover {
    color: #00bfff;
    // text-shadow: 3px 3px 6px #215ca0;
    text-decoration: none;
  } */
  @media screen and (max-width: 992px) {
    /* margin-left: 0px;
    align-content: center;
    text-align: center;
    margin-right: 0px; */
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    margin-left: 0px;
    align-content: center;
    text-align: center;
    margin-right: 0px;
    color: black;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    padding: 12px;
  }
`
const TryItFreeButton = styled.a`
  /* display: inline-block; */
  text-decoration: none !important;
  font-family: "Roboto";
  padding: 11px 20px;
  margin-right: 20px;
  background: #ee6352;
  border-radius: 4px;
  font-style: normal;
  font-weight: 100;
  font-size: 1em;
  line-height: 26px;
  color: #ffffff !important;

  /* &:hover {
    color: orange;
    text-decoration: none;
    text-shadow: "3px 3px 6px #215ca0";
  } */
  @media screen and (max-width: 992px) {
    /* text-decoration: none;
    background: rgb(21 19 19 / 63%);
    color: whitesmoke;
   font-size:0.875em;
    font-weight: bold;
    font-family: "Roboto";
   
    border-radius: 0.1rem;
    padding-top: 9px;
    margin-left: -5px;
    margin-top: -32px;
    width: 293px;
    padding: 16px 17px;
    height: 52px;
    margin-right: -3px;
    border-radius: 8px; */
  }

  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    // margin-left: 0px;
    // align-content: center;
    // text-align: center;
    // margin-right: 0px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    display: none;
  }
`
const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  width: 150px;
  margin-left: 1rem;
  @media screen and (max-width: 600px) {
    height: 60px;
  }
`
const SubLinkMobile = styled(Link)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  font-size: 1.25em;
  line-height: 26px;
  color: #333333;
  text-decoration: none;
  text-transform: inherit;
  margin-right: 35px;
  @media screen and (max-width: 600px) {
    padding: 1rem;
    font-size: 1.2rem;
    box-shadow: 0 1px 3px rgb(0 0 0 / 5%);
    border-bottom: 1px solid rgb(102, 102, 102);
    text-align: center;
  }
`
const MenuMobile = styled(Link)`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 1200px) {
    display: none;
  }
`
const BlockOnMobile = styled.div`
  display: block;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`
const BlockOnLargeScreen = styled.div`
  display: none;
  @media only screen and (max-width: 600px) {
    display: block;
  }
`
const SolutionsDropdown = styled(Dropdown)`
  /* font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  font-size: 1em;
  line-height: 26px;
  color: #333333;
  text-decoration: none;
  text-transform: inherit;
  margin-right: 35px;
  margin-left: -42px; */

  &:hover {
    /* color: #000;
    text-shadow: 3px 3px 6px #215ca0; */
    text-decoration: none;
  }
  @media screen and (min-width: 992px) {
    /* color: black;
    padding: 0.1rem 0.4rem;
    text-decoration: none;
    margin-right: 10px;
    margin-top: 30px; */
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    color: black;
    &:hover {
      color: black;
      text-shadow: 3px 3px 6px #215ca0;
      text-decoration: none;
    }
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    margin-right: 13px;
    font-size: 1em;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    margin-right: 13px;
    font-size: 1em;
  }
`
