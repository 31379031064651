import React from "react"

import PropTypes from "prop-types"

import Navbar from "./Navbar"
import Megamenu from "./UI/Megamenu"

function Header() {
  const [show, setShow] = React.useState(false)

  function handleDropdownClick(data) {
    setShow(data)
  }
  return (
    <>
      <Navbar handleDropdownClick={handleDropdownClick} show={show} />
      {show && <Megamenu />}
    </>
  )
}

// const Header = ({ siteTitle }) => (
//   <>
//     <Navbar />
//     <Megamenu />
//   </>
// )

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
