import React, { Component } from "react"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"

class Cookie extends Component {
  render() {
    return (
      <CookieConsent
        location="bottom"
        buttonText="I Understand"
        cookieName="tekorero"
        style={{ background: "black" }}
        buttonStyle={{
          color: "#24262a",
          backgroundColor: "#fad145",
          border: "0",
          borderRadius: "0px",
          boxShadow: "none",
          color: "black",
          cursor: "pointer",
          flex: "0 0 auto",
          padding: "5px 10px",
          // margin: "15px",
          // width: "24%",
    marginTop: "15px",
    marginLeft: "145px",
    marginRight: "351px",
    marginBottom: "7px",
          width:"32%",
          marginLeft: "20px",
        }}
        expires={150}
        acceptOnScroll={false}
        onAccept={({ acceptedByScrolling }) => {
          // if (acceptedByScrolling) {
          //   // triggered if user scrolls past threshold
          //   alert("Accept was triggered by user scrolling")
          // } else {
          //   alert("Accept was triggered by clicking the Accept button")
          // }
        }}
        // enableDeclineButton
        // onDecline={() => {}}
      >
        Korero uses cookie for functional and analytical purposes. Please read
        our <Link to="/privacypolicy/">Cookie Policy</Link> for more
        information.
      </CookieConsent>
    )
  }
}
export default Cookie
