import React, { Component } from "react"
import CARDIMG1 from "../../images/outofboxNav.jpg"
import CARDIMG2 from "../../images/customerjourneyNav.jpg"
import CARDIMG3 from "../../images/automationNav.jpg"
import CARDIMG4 from "../../images/leadsNav.jpg"
import CARDIMG5 from "../../images/collaborationNav.jpg"
// import CARDIMG6 from "../../images/Security.jpg"
import { Link } from "gatsby"
import styled from "styled-components"
export default class Megamenu extends Component {
  render() {
    return (
      <div>
        <Menu>
          <MegaMenuShow>
            <MegaMenu>
              <MenuOption>
                <SmallBox>
                  <SamllLogo>
                    <img src={CARDIMG1} alt="img1" />
                  </SamllLogo>
                  <ContentBox>
                    <Link to="/outoftheboxuse/" style={{ color: "white" }}>
                      <Heading>Out-of-the-Box </Heading>
                    </Link>
                    <Content>Get started in minutes</Content>
                  </ContentBox>
                </SmallBox>
                <SmallBox>
                  <SamllLogo>
                    <img src={CARDIMG2} alt="img2" />
                  </SamllLogo>
                  <ContentBox>
                    <Link to="/customerjourney/" style={{ color: "white" }}>
                      <Heading>Customer journey</Heading>
                    </Link>
                    <Content>
                      Opportunity life cycle integrated with workflows and tasks
                      for better and faster results
                    </Content>
                  </ContentBox>
                </SmallBox>

                <SmallBox>
                  <SamllLogo>
                    <img src={CARDIMG3} alt="img3" />
                  </SamllLogo>
                  <ContentBox>
                    <Link to="/automation/" style={{ color: "white" }}>
                      <Heading>Automation - value to business</Heading>
                    </Link>
                    <Content>
                      Easy configuration of processes to improve productivity
                    </Content>
                  </ContentBox>
                </SmallBox>
              </MenuOption>

              <MenuOption>
                <SmallBox>
                  <SamllLogo>
                    <img src={CARDIMG4} alt="img4" />
                  </SamllLogo>
                  <ContentBox>
                    <Link
                      to="/leadsopportunitiesandclosures/"
                      style={{ color: "white" }}
                    >
                      <Heading>Leads to Closures</Heading>
                    </Link>
                    <Content>
                      Perspectives and tile representation for better User
                      experience
                    </Content>
                  </ContentBox>
                </SmallBox>

                <SmallBox>
                  <SamllLogo>
                    <img src={CARDIMG5} alt="img5" />
                  </SamllLogo>
                  <ContentBox>
                    <Link
                      to="/collaboratebreakthesilos/"
                      style={{ color: "white" }}
                    >
                      <Heading>Collaborate "Break the silos"</Heading>
                    </Link>
                    <Content>
                      Include users from outside sales functions for faster
                      closures and improved governance
                    </Content>
                  </ContentBox>
                </SmallBox>

                <SmallBox>
                  <SamllLogo>
                    {/* <img src={CARDIMG6} alt="img6" /> */}
                  </SamllLogo>
                  <ContentBox>
                    {/* <Link to="/securityandaccess/" style={{ color: "white" }}>
                      <Heading>Security and Access</Heading>
                    </Link> */}
                    <Content></Content>
                  </ContentBox>
                </SmallBox>
              </MenuOption>
            </MegaMenu>
          </MegaMenuShow>
        </Menu>
      </div>
    )
  }
}

const Menu = styled.div`
  display: block;
`

const MegaMenuShow = styled.div`
  @media screen and (max-width: 600px) {
    display: none;
  }
  @media only screen and (min-width: 1200px) {
    display: block;
  }
`
const MegaMenu = styled.div`
  position: absolute;
  background: #494949;
  color: #fff;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  left: 0;
  top: 60px;
  -webkit-transition: max-height 0.5s;
  transition: max-height 0.5s;
  overflow: hidden;
  z-index: 4;
  margin-top: 15px;
  position: fixed;
  top: 60;
`
const MenuOption = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
`
const SmallBox = styled.div`
  width: 30%;

  justify-content: space-between;
  display: flex;
`
const SamllLogo = styled.div`
  padding: 10px;
`
const ContentBox = styled.div`
  width: 75%;
`

const Heading = styled.span`
  display: block;
  font-family: "Roboto";
  font-size: 0.875em;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  line-height: 18px;
`
const Content = styled.div`
  display: block;
  font-family: "Roboto";
  font-size: 0.75em;
  line-height: 15px;
  letter-spacing: 1px;
  margin-top: 10px;
  font-weight: 100;
`
